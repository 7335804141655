import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"

import { Header } from "../components/Header"
import { ButtonLabel } from "../elements/ButtonLabel"
import { IconArrow } from "../icons/IconArrow"

import {
  BUTTON__BACK_TO_MAP,
  PAGE_NOT_FOUND,
  SITENAME,
} from "../providers/texts"

import styles from "./404.module.scss"
import { SEO } from "../components/Seo"

const NotFoundPage: React.FC = () => {
  const heroImage = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "_general/notfound.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <SEO title={`${SITENAME} • ${PAGE_NOT_FOUND}`} />
      <div className={styles.container}>
        <Header resetState={() => navigate("/")} />

        <div className={styles.content}>
          <Img
            fluid={heroImage.file.childImageSharp.fluid}
            className={styles.image}
            alt={PAGE_NOT_FOUND}
          />
          <div className={styles.text}>
            <div className={styles.description}>
              <div className={styles.description__title}>
                Tady žádná přebalovna není...
              </div>
              <div className={styles.description__paragraph}>
                Moc mě to mrzí, ale tuto stránku jsem nenašla.
              </div>
            </div>

            <div className={styles.button}>
              <Link to="/">
                <ButtonLabel
                  ariaLabel={BUTTON__BACK_TO_MAP}
                  onClick={() => null}
                >
                  <>
                    <IconArrow />
                    <div className={styles.button__label}>
                      {BUTTON__BACK_TO_MAP}
                    </div>
                  </>
                </ButtonLabel>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
